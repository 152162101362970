import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Collapse,
  IconButton,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { IQFormInput } from '@gannettdigital/shared-react-components';
import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';
import PasswordInputRequirements,
{
  PASSWORD_MISSING_LOWERCASE,
  PASSWORD_MISSING_NUMBER,
  PASSWORD_MISSING_UPPERCASE,
  PASSWORD_TOO_SHORT,
} from '../InputRequirements/PasswordInputRequirements';

const PASSWORD_NUMBER_REGEX = /^.*(?=.*\d).*$/;
const PASSWORD_LOWERCASE_REGEX = /^.*(?=.*[a-z]).*$/;
const PASSWORD_UPPERCASE_REGEX = /^.*(?=.*[A-Z]).*$/;
const REQUIRED_MESSAGE = 'Password is required';
const MATCH_MESSAGE = 'Must match entered password';

export const PasswordCreationValidation = yup.string().required(REQUIRED_MESSAGE)
  .matches(
    PASSWORD_NUMBER_REGEX,
    PASSWORD_MISSING_NUMBER,
  )
  .matches(
    PASSWORD_LOWERCASE_REGEX,
    PASSWORD_MISSING_LOWERCASE,
  )
  .matches(
    PASSWORD_UPPERCASE_REGEX,
    PASSWORD_MISSING_UPPERCASE,
  )
  .min(
    8,
    PASSWORD_TOO_SHORT,
  );

export const PasswordEntryValidation = yup.string().required(REQUIRED_MESSAGE);
/**
 * Use the below for matching an existing password
 * @param {string} passwordKey: The name key used to identify the password field to match
 * against in the schema
 */
export const PasswordConfirmValidation = (passwordKey) => yup.string()
  .required(MATCH_MESSAGE)
  .oneOf(
    [yup.ref(passwordKey), null],
    MATCH_MESSAGE,
  );

export default function PasswordInput({
  id,
  textId,
  name,
  label,
  showRequirements,
  hasSignInError,
  disabled,
  errorId
}) {
  const [showPlainTextPassword, setShowPlainTextPassword] = useState(false);
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);

  const handleClickShowPassword = () => {
    setShowPlainTextPassword(!showPlainTextPassword);
  };

  const { formState: { errors } } = useFormContext();

  const endAdornment = (
    <IconButton
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      name="showPassword"
      size="large"
      sx={{ margin: 0, padding: 0, marginTop: '2px' }}
    >
      {showPlainTextPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  );

  const passwordInputReq = (
    <Collapse timeout={500} in={showPasswordRequirements}>
      <PasswordInputRequirements
        inputKey={name}
      />
    </Collapse>
  );

  const passwordFocused = (showFocus) => {
    if (showRequirements) {
      if (showFocus) {
        setShowPasswordRequirements(showFocus);
      } else {
        // If we are trying to hide, make sure we don't have any outstanding errors
        const passwordErrors = errors.password;
        const hasErrors = passwordErrors
          && passwordErrors.types
          && Object.keys(passwordErrors.types).length > 0;
        if (!hasErrors) {
          setShowPasswordRequirements(showFocus);
        }
      }
    }
  };

  return (
    <>
      <IQFormInput
        name={name}
        id={id}        
        textId={textId}
        labelText={label}
        onFocus={() => passwordFocused(true)}
        onBlur={() => passwordFocused(false)}
        showError={!showPasswordRequirements}
        adornmentIcon={endAdornment}
        adornmentPosition="END"
        obscureValue={!showPlainTextPassword}
        directErrorReference={hasSignInError && {message: 'Incorrect Password'}}
        fullWidth
        inputRadius="12px"
        disabled={disabled}
        errorId={errorId}
      />
      {showRequirements && passwordInputReq}
    </>
  );
}

PasswordInput.propTypes = {

  /**
   * Unique ID of this component
   */
  id: PropTypes.string.isRequired,
  textId: PropTypes.string.isRequired,

  /**
   * The unique name for this form field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label that appears as a placeholder
   */
  label: PropTypes.string,

  /**
   * Whether to show the input requirements for password
   */
  showRequirements: PropTypes.bool,

};

PasswordInput.defaultProps = {
  label: 'Password',
  showRequirements: false,
};
