import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { validateIpAddressErrorAction } from './app-slice';
import {
  loginFailureAction,
  tokenValid,
  resetLoginErrorAction,
} from '../Login/login-slice';
import { logoutFailureAction } from '../Logout/logout-slice';
import {
  createAccountAction,
  createAccountDuplicateEmailErrorAction,
  createAccountFailureAction,
  createAccountExistingPlatformUserErrorAction,
  resetCreateAccountError,
} from '../CreateAccount/create-account-slice';
import {
  createBusinessErrorAction,
} from '../CreateBusiness/create-business-slice';
import {
  updatePasswordErrorAction,
  requestResetPasswordErrorAction,
  updatePasswordAction,
  requestResetPasswordAction,
} from '../ForgotPassword/forgot-password-slice';
import { ACCOUNT_BLOCKED_ERROR_MESSAGE } from './constants';

const errorSlice = createSlice({
  name: 'error',
  initialState: {
    duplicateError: false,
    accountCreationError: false,
    createBusinessError: false,
    existingPlatformUserError: false,
    loginError: false,
    loginErrorStatusCode: 0,
    loginFailedAttempts: 0,
    isLoginLocked: false,
    requestPasswordResetError: false,
    resetPasswordExecutionError: false,
    resetPasswordExecutionErrorCode: null,
    logoutUserError: false,
    missingBusinessInfo: false,
    ipAddressValidationErrorData: null,
  },
  extraReducers: (builder) => {
    builder.addCase(createAccountDuplicateEmailErrorAction, (state) => {
      state.duplicateError = true;
      state.accountCreationError = false;
      state.existingPlatformUserError = false;
    });
    builder.addCase(createAccountExistingPlatformUserErrorAction, (state) => {
      state.existingPlatformUserError = true;
      state.duplicateAccountError = false;
      state.accountCreationError = false;
    });
    builder.addCase(createAccountFailureAction, (state) => {
      state.accountCreationError = true;
    });
    builder.addCase(resetCreateAccountError, (state) => {
      state.accountCreationError = false;
      state.duplicateError = false;
    });
    builder.addCase(createBusinessErrorAction, (state) => {
      state.createBusinessError = true;
    });
    builder.addCase(createAccountAction, (state) => {
      state.createBusinessError = false;
    });
    builder.addCase(loginFailureAction, (state, action) => {
      state.loginError = true;
      state.loginErrorStatusCode = action.payload.response.statusCode;
      state.loginFailedAttempts = action.payload.response.failedAttempts;
      state.isLoginLocked = false;
      if(action.payload.response.message === ACCOUNT_BLOCKED_ERROR_MESSAGE) {
        state.isLoginLocked = true;
      }
    });
    builder.addCase(resetLoginErrorAction, (state) => {
      state.loginError = false;
    });
    builder.addCase(requestResetPasswordErrorAction, (state) => {
      state.requestPasswordResetError = true;
    });
    builder.addCase(updatePasswordErrorAction, (state, action) => {
      state.resetPasswordExecutionError = true;
      state.resetPasswordExecutionErrorCode = action.payload.response.statusCode;
    });
    builder.addCase(updatePasswordAction, (state) => {
      state.resetPasswordExecutionError = false;
      state.resetPasswordExecutionErrorCode = null;
    });
    builder.addCase(logoutFailureAction, (state) => {
      state.logoutUserError = true;
    });
    builder.addCase(tokenValid, (state, action) => {
      state.missingBusinessInfo = !action.payload.businessName;
    });
    builder.addCase(validateIpAddressErrorAction, (state, action) => {
      state.ipAddressValidationErrorData = action.payload.response;
    });
    builder.addMatcher(isAnyOf(
      requestResetPasswordAction,
      requestResetPasswordErrorAction,
    ), (state) => {
      state.requestPasswordResetError = false;
    });
  },
});

export default errorSlice.reducer;
