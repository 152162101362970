import makeStyles from '@mui/styles/makeStyles';
import styled from '@emotion/styled';
import { Container, Grid, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ls from 'local-storage';
import { IQLoadingSpinner, ValidationProvider } from '@gannettdigital/shared-react-components';
import PasswordInput, { PasswordEntryValidation } from '../shared/PasswordInput/PasswordInput';
import { changeTemplateAction } from '../shared/app-slice';
import { loginAction, resetLoginErrorAction, setEnteredEmailAction } from './login-slice';
import EmailInput, { EmailValidation } from '../shared/EmailInput/EmailInput';
import {
  FORGOT_PASSWORD_PATH,
  REGISTER_PATH,
  SUPPORT_EMAIL,
  TEMPLATE_LS_KEY,
} from '../shared/constants';
import TextLinkButton from '../shared/TextLinkButton/TextLinkButton';
import { getLoginErrorMessage } from '../shared/utils/login-utils';
import GoogleLoginButton from './SocialLogin/GoogleLoginButton';
import {
  ContentFormGrid,
  GoogleBtnGridRow,
  LineBlock,
  PrimaryButton,
  SpacedGrid,
} from '../shared/styled/shared-styled-components';
import ActionCard, { ERROR_TYPE, SUCCESS_TYPE, WARNING_TYPE } from '../shared/ActionCard/ActionCard';
import LogoHeader from '../shared/LogoHeader/LogoHeader';

const {
  REACT_APP_GOOGLE_CLIENT_ID,
} = process.env;

const LoginContainer = styled(Container)(({ theme }) => ({
  width: 500,
  '@media (min-width: 600px)': {
    paddingLeft: 0,
    paddingRight: 0,
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 20px 80px 20px',
    width: '100%',
  },
}));

const CustomPasswordBtn = styled(TextLinkButton)(({ theme }) => ({
  'a': {
    marginTop: theme.spacing(1),
    padding: 0,
  }
}));

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  loginTitle: {
    fontSize: '32px',
    fontFamily: 'Unify Sans SemiBold',
  },
  freemiumLoginTitle: {
    fontSize: '20px',
    fontFamily: 'Unify Sans SemiBold',
    marginBottom: '24px',
    textAlign: 'center',
  },
  loginButton: {
    borderRadius: '8px'
  },
  loginFooterText: {
    marginTop: theme.spacing(3)
  }
}));

export default function LoginForm({ showLoading, authReady }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const template = useSelector((state) => state.externalSettings.template);
  const defaultEmail = useSelector((state) => state.externalSettings.prePopulateEmail);
  const hasResetPassword = useSelector((state) => state.forgotPassword.passwordResetComplete);
  const hasSignInError = useSelector((state) => state.error.loginError);
  const errorStatusCode = useSelector((state) => state.error.loginErrorStatusCode);
  const loginFailedAttempts = useSelector((state) => state.error.loginFailedAttempts);
  const isLoginLocked = useSelector((state) => state.error.isLoginLocked);
  const enteredEmail = useSelector((state) => state.loading.enteredEmail);
  const existingPlatformUserError = useSelector((state) => state.error.existingPlatformUserError);

  const responsiveCheck = {
    maxWidth: 500,
    width: isMobile ? 'auto' : 500,
  };

  const schema = yup.object().shape({
    email: EmailValidation,
    password: PasswordEntryValidation,
  });

  const methods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { handleSubmit, trigger, setValue } = methods;

  const onSubmit = async (data) => {
    dispatch(resetLoginErrorAction());
    dispatch(loginAction({ email: data.email, password: data.password }));
    dispatch(setEnteredEmailAction({ email: data.email }));
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    // Reset errors before leaving
    dispatch(resetLoginErrorAction());
    navigate(FORGOT_PASSWORD_PATH);
  };

  const handleSignUpLink = (e) => {
    e.preventDefault();
    // Reset errors before leaving

    // TODO: Remove this freemium check once they both support a single UI
    // Currently we need a difference since one does not allow registration / google
    if (template.key === 'default') {
      setValue('email', '');
      setValue('password', '');
      dispatch(changeTemplateAction({ template: 'freemium' }));
      dispatch(resetLoginErrorAction());
      ls.set(TEMPLATE_LS_KEY, template.key);
    } else {
      dispatch(resetLoginErrorAction());
      navigate(REGISTER_PATH);
    }
  };

  return (
    <>
      <LogoHeader />
      <LoginContainer>
        {hasResetPassword && (
          <ActionCard
            id="reset-banner"
            type={SUCCESS_TYPE}
            style={{ marginBottom: 25 }}
            textId="login-successful-password-reset"
          >
            Your password has been successfully reset.
          </ActionCard>
        )}
        {((hasSignInError || isLoginLocked)) && (
          <ActionCard
            id="sign-in-error-banner"
            type={ERROR_TYPE}
            style={{ marginBottom: 25 }}
          >
            <span id="login-error">{getLoginErrorMessage(errorStatusCode, enteredEmail, loginFailedAttempts)}</span>
            {isLoginLocked && (<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}.</a>)}
          </ActionCard>
        )}
        {existingPlatformUserError && (
          <ActionCard
            id="existing-platform-user-banner"
            type={WARNING_TYPE}
            style={{ marginBottom: 25 }}
          >
            Looks like you already have a LocaliQ account.
            Contact your current rep to learn more about your LocaliQ
            and the solutions that are right for your business.
          </ActionCard>
        )}
        <div className={classes.mainWrapper}>
          <ValidationProvider schema={schema}>
            <FormProvider {...methods}>
              <form
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                style={responsiveCheck}
              >
                <ContentFormGrid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {!template.supportGoogle && <Grid item xs={12} sx={{ '&.MuiGrid-root.MuiGrid-item': { paddingTop: 0 } }}>
                    <Typography id="social-signin-title" className={classes.loginTitle} >Login</Typography>
                  </Grid>}
                  {template.supportGoogle && (
                    <>
                      <Grid item xs={12} sx={{ '&.MuiGrid-root.MuiGrid-item': { paddingTop: 0 } }}>
                        <Typography id="social-signin-title" className={classes.freemiumLoginTitle} >Log in to your account</Typography>
                      </Grid>
                      <GoogleBtnGridRow item xs={12}>
                        <GoogleLoginButton clientId={REACT_APP_GOOGLE_CLIENT_ID} />
                      </GoogleBtnGridRow>
                      <SpacedGrid item xs={12}>
                        <LineBlock id="social-divider" >OR</LineBlock>
                      </SpacedGrid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <EmailInput
                      id="email-input-login"
                      textId="email-input-login-label"
                      name="email"
                      defaultEmail={defaultEmail}
                      fullWidth
                      trigger={trigger}
                      disabled={showLoading && !hasSignInError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordInput
                      id="login-password"
                      textId="login-password-label"
                      name="password"
                      label="Password"
                      hasSignInError={hasSignInError}
                      disabled={showLoading && !hasSignInError}
                    />
                    <CustomPasswordBtn
                      className={classes.forgotPassword}
                      onClick={handleForgotPassword}
                      linkText="Forgot password?"
                      btnPath={FORGOT_PASSWORD_PATH}
                      id="forget-password-label"
                      buttonId="forget-password-button"
                      disabled={showLoading && !hasSignInError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PrimaryButton
                      variant="contained"
                      id="login-btn"
                      type="submit"
                      color="primary"
                      className={classes.loginButton}
                      disabled={showLoading && authReady && !hasSignInError}
                    >
                      {(showLoading && authReady && !hasSignInError) ? <IQLoadingSpinner size={20} /> : template.login.ctaText || 'Sign In'}
                    </PrimaryButton>
                  </Grid>
                </ContentFormGrid>
              </form>
            </FormProvider>
          </ValidationProvider>
        </div>
        <TextLinkButton
          id="dont-have-acct-text"
          className={classes.loginFooterText}
          text={template.login.footerText || ''}
          linkCallback={handleSignUpLink}
          btnPath={REGISTER_PATH}
          ids={{
            linkId: "signup-login-button",
          }}
          linkText={template.login.footerLinkText}
          disabled={showLoading && !hasSignInError}
        />
      </LoginContainer>
    </>
  );
}
